<script>
import {
  ArrowUpIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  PlayIcon,
} from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";

import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";

/**
 * Index-event component
 */
export default {
  data() {
    return {
      start: "",
      end: "",
      interval: "",
      days: "",
      minutes: "",
      hours: "",
      seconds: "",
      starttime: "October 11, 2020 15:37:25",
      endtime: "Dec 31, 2020 16:37:25",
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    Carousel,
    Slide,
    ArrowUpIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    PlayIcon,
  },
  mounted() {
    this.start = new Date(this.starttime).getTime();
    this.end = new Date(this.endtime).getTime();
    // Update the count down every 1 second
    this.timerCount(this.start, this.end);
    this.interval = setInterval(() => {
      this.timerCount(this.start, this.end);
    }, 1000);
  },
  methods: {
    timerCount: function (start, end) {
      // Get todays date and time
      var now = new Date().getTime();

      // Find the distance between now an the count down date
      var distance = start - now;
      var passTime = end - now;

      if (distance < 0 && passTime < 0) {
        clearInterval(this.interval);
        return;
      } else if (distance < 0 && passTime > 0) {
        this.calcTime(passTime);
      } else if (distance > 0 && passTime > 0) {
        this.calcTime(distance);
      }
    },
    calcTime: function (dist) {
      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((dist % (1000 * 60)) / 1000);
    },
  },
};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />

    <!-- Hero Start -->
    <section
      class="bg-half-260 w-100 d-table"
      data-jarallax='{"speed": 0.5}'
      style="background: url('images/event/event.jpg') center center"
    >
      <div class="bg-overlay bg-overlay-gradient"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 text-center">
            <div class="title-heading">
              <h4 class="text-success mb-3">11th October, 2020</h4>
              <h1 class="display-4 title-dark text-white font-weight-bold mb-3">
                Digital Conference 2020-21
              </h1>
              <p class="para-desc title-dark mx-auto text-light">
                Launch your campaign and benefit from our expertise on designing
                and managing conversion centered bootstrap4 html page.
              </p>

              <div class="row">
                <div class="col-md-12 text-center">
                  <div id="eventdown">
                    <div class="count-down">
                      <span class="count-number">{{ days }}</span>
                      <span class="count-head position-relative d-bock"
                        >Days</span
                      >
                    </div>
                    <div class="count-down">
                      <span class="count-number">{{ hours }}</span>
                      <span class="count-head position-relative d-bock"
                        >Hours</span
                      >
                    </div>
                    <div class="count-down">
                      <span class="count-number">{{ minutes }}</span>
                      <span class="count-head position-relative d-bock"
                        >Minutes</span
                      >
                    </div>
                    <div class="count-down">
                      <span class="count-number">{{ seconds }}</span>
                      <span class="count-head position-relative d-bock"
                        >Seconds</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-4 pt-2">
                <a href="javascript:void(0)" class="btn btn-success mt-2 mr-2"
                  ><i class="mdi mdi-ticket"></i> Buy Tickets</a
                >
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-footer">
        <div class="text-center bg-white p-4">
          <h5 class="text-dark mb-0">Next Event : 15th March, 2022</h5>
        </div>
      </div>
    </div>
    <!-- Hero End -->

    <!-- About START -->
    <section class="section border-top">
      <div class="container">
        <div class="card rounded shadow border-0 bg-light overflow-hidden">
          <div class="row no-gutters align-items-center">
            <div class="col-lg-6">
              <img src="/images/event/about.jpg" class="img-fluid" alt="" />
            </div>
            <!--end col-->
            <div class="col-lg-6">
              <div class="card-body section-title p-md-5">
                <h4 class="title mb-4">Business Startup Conference</h4>
                <p class="text-muted para-desc mb-0">
                  Start working with Landrick that can provide everything you
                  need to generate awareness, drive traffic, connect. Launch
                  your campaign and benefit from our expertise on designing and
                  managing conversion centered bootstrap4 html page managing
                  conversion centered bootstrap4 html page.
                </p>
                <div class="mt-4 pt-2">
                  <a href="javascript:void(0)" class="btn btn-outline-primary"
                    >Read More <i class="mdi mdi-chevron-right"></i
                  ></a>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end card-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- About End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-light">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Speakers Start -->
    <section class="section bg-light">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Our Speakers</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card team text-center rounded border-0">
              <div class="card-body">
                <div class="position-relative">
                  <img
                    src="/images///client/01.jpg"
                    class="
                      img-fluid
                      avatar avatar-ex-large
                      rounded-circle
                      shadow
                    "
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <a href="javascript:void(0)" class="rounded">
                        <facebook-icon
                          class="fea icon-sm fea-social"
                        ></facebook-icon>
                      </a>
                    </li>
                    <li class="list-inline-item ml-1">
                      <a href="javascript:void(0)" class="rounded">
                        <instagram-icon
                          class="fea icon-sm fea-social"
                        ></instagram-icon>
                      </a>
                    </li>
                    <li class="list-inline-item ml-1">
                      <a href="javascript:void(0)" class="rounded">
                        <twitter-icon
                          class="fea icon-sm fea-social"
                        ></twitter-icon>
                      </a>
                    </li>
                    <li class="list-inline-item ml-1">
                      <a href="javascript:void(0)" class="rounded">
                        <linkedin-icon
                          class="fea icon-sm fea-social"
                        ></linkedin-icon>
                      </a>
                    </li>
                  </ul>
                  <!--end icon-->
                </div>
                <div class="content pt-3">
                  <h5 class="mb-0">
                    <a href="javascript:void(0)" class="name text-dark"
                      >Ronny Jofra</a
                    >
                  </h5>
                  <small class="designation text-muted">Organizer</small>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card team text-center rounded border-0">
              <div class="card-body">
                <div class="position-relative">
                  <img
                    src="/images///client/04.jpg"
                    class="
                      img-fluid
                      avatar avatar-ex-large
                      rounded-circle
                      shadow
                    "
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <a href="javascript:void(0)" class="rounded">
                        <facebook-icon
                          class="fea icon-sm fea-social"
                        ></facebook-icon>
                      </a>
                    </li>
                    <li class="list-inline-item ml-1">
                      <a href="javascript:void(0)" class="rounded">
                        <instagram-icon
                          class="fea icon-sm fea-social"
                        ></instagram-icon>
                      </a>
                    </li>
                    <li class="list-inline-item ml-1">
                      <a href="javascript:void(0)" class="rounded">
                        <twitter-icon
                          class="fea icon-sm fea-social"
                        ></twitter-icon>
                      </a>
                    </li>
                    <li class="list-inline-item ml-1">
                      <a href="javascript:void(0)" class="rounded">
                        <linkedin-icon
                          class="fea icon-sm fea-social"
                        ></linkedin-icon>
                      </a>
                    </li>
                  </ul>
                  <!--end icon-->
                </div>
                <div class="content pt-3">
                  <h5 class="mb-0">
                    <a href="javascript:void(0)" class="name text-dark"
                      >Micheal Carlo</a
                    >
                  </h5>
                  <small class="designation text-muted">Event Manager</small>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card team text-center rounded border-0">
              <div class="card-body">
                <div class="position-relative">
                  <img
                    src="/images///client/03.jpg"
                    class="
                      img-fluid
                      avatar avatar-ex-large
                      rounded-circle
                      shadow
                    "
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <a href="javascript:void(0)" class="rounded">
                        <facebook-icon
                          class="fea icon-sm fea-social"
                        ></facebook-icon>
                      </a>
                    </li>
                    <li class="list-inline-item ml-1">
                      <a href="javascript:void(0)" class="rounded">
                        <instagram-icon
                          class="fea icon-sm fea-social"
                        ></instagram-icon>
                      </a>
                    </li>
                    <li class="list-inline-item ml-1">
                      <a href="javascript:void(0)" class="rounded">
                        <twitter-icon
                          class="fea icon-sm fea-social"
                        ></twitter-icon>
                      </a>
                    </li>
                    <li class="list-inline-item ml-1">
                      <a href="javascript:void(0)" class="rounded">
                        <linkedin-icon
                          class="fea icon-sm fea-social"
                        ></linkedin-icon>
                      </a>
                    </li>
                  </ul>
                  <!--end icon-->
                </div>
                <div class="content pt-3">
                  <h5 class="mb-0">
                    <a href="javascript:void(0)" class="name text-dark"
                      >Aliana Rosy</a
                    >
                  </h5>
                  <small class="designation text-muted">Motivator</small>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div class="card team text-center rounded border-0">
              <div class="card-body">
                <div class="position-relative">
                  <img
                    src="/images///client/02.jpg"
                    class="
                      img-fluid
                      avatar avatar-ex-large
                      rounded-circle
                      shadow
                    "
                    alt=""
                  />
                  <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                    <li class="list-inline-item">
                      <a href="javascript:void(0)" class="rounded">
                        <facebook-icon
                          class="fea icon-sm fea-social"
                        ></facebook-icon>
                      </a>
                    </li>
                    <li class="list-inline-item ml-1">
                      <a href="javascript:void(0)" class="rounded">
                        <instagram-icon
                          class="fea icon-sm fea-social"
                        ></instagram-icon>
                      </a>
                    </li>
                    <li class="list-inline-item ml-1">
                      <a href="javascript:void(0)" class="rounded">
                        <twitter-icon
                          class="fea icon-sm fea-social"
                        ></twitter-icon>
                      </a>
                    </li>
                    <li class="list-inline-item ml-1">
                      <a href="javascript:void(0)" class="rounded">
                        <linkedin-icon
                          class="fea icon-sm fea-social"
                        ></linkedin-icon>
                      </a>
                    </li>
                  </ul>
                  <!--end icon-->
                </div>
                <div class="content pt-3">
                  <h5 class="mb-0">
                    <a href="javascript:void(0)" class="name text-dark"
                      >Sofia Razaq</a
                    >
                  </h5>
                  <small class="designation text-muted">Speaker</small>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-50">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Event Sponsors</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
          <div class="col-lg-2 col-md-2 col-6 text-center mt-4 pt-2">
            <img
              src="/images///client/amazon.svg"
              class="avatar avatar-ex-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center mt-4 pt-2">
            <img
              src="/images///client/google.svg"
              class="avatar avatar-ex-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center mt-4 pt-2">
            <img
              src="/images///client/lenovo.svg"
              class="avatar avatar-ex-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center mt-4 pt-2">
            <img
              src="/images///client/paypal.svg"
              class="avatar avatar-ex-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center mt-4 pt-2">
            <img
              src="/images///client/shopify.svg"
              class="avatar avatar-ex-sm"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-2 col-6 text-center mt-4 pt-2">
            <img
              src="/images///client/spotify.svg"
              class="avatar avatar-ex-sm"
              alt=""
            />
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Speakers End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Schedule Start -->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Event Schedules</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row mt-4 pt-2 justify-content-center">
          <div class="col-12 text-center">
            <b-tabs
              pills
              justified
              content-class="row col-12"
              nav-wrapper-class="col-lg-8 col-md-12 offset-md-2"
            >
              <b-tab>
                <template v-slot:title>
                  <div class="text-center pt-1 pb-1">
                    <h4 class="title font-weight-normal mb-0">First Day</h4>
                  </div>
                </template>
                <div class="row">
                  <div class="col-lg-6 mt-4 pt-2">
                    <div class="card event-schedule rounded border">
                      <div class="card-body">
                        <div class="media">
                          <ul
                            class="
                              date
                              text-center text-primary
                              mr-3
                              mb-0
                              list-unstyled
                            "
                          >
                            <li class="day font-weight-bold mb-2">11</li>
                            <li class="month font-weight-bold">OCT</li>
                          </ul>
                          <div class="media-body content">
                            <h4>
                              <a
                                href="javascript:void(0)"
                                class="text-dark title"
                                >Digital Conference Event Intro</a
                              >
                            </h4>
                            <p class="text-muted location-time">
                              <span class="text-dark h6">Address:</span> Hall 3,
                              Sinchang-dong, Kwangju,
                              <span class="text-danger">South Korea</span>
                              <br />
                              <span class="text-dark h6">Time:</span> 10:30AM to
                              11:15AM
                            </p>
                            <a
                              href="#tickets"
                              class="btn btn-sm btn-outline-primary mouse-down"
                              >Buy Ticket</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-6 mt-4 pt-2">
                    <div class="card event-schedule rounded border">
                      <div class="card-body">
                        <div class="media">
                          <ul
                            class="
                              date
                              text-center text-primary
                              mr-3
                              mb-0
                              list-unstyled
                            "
                          >
                            <li class="day font-weight-bold mb-2">11</li>
                            <li class="month font-weight-bold">OCT</li>
                          </ul>
                          <div class="media-body content">
                            <h4>
                              <a
                                href="javascript:void(0)"
                                class="text-dark title"
                                >Conference On User Interface</a
                              >
                            </h4>
                            <p class="text-muted location-time">
                              <span class="text-dark h6">Address:</span> Hall 3,
                              Sinchang-dong, Kwangju,
                              <span class="text-danger">South Korea</span>
                              <br />
                              <span class="text-dark h6">Time:</span> 11:15AM to
                              12:30PM
                            </p>
                            <a
                              href="#tickets"
                              class="btn btn-sm btn-outline-primary mouse-down"
                              >Buy Ticket</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-6 mt-4 pt-2">
                    <div class="card event-schedule rounded border">
                      <div class="card-body">
                        <div class="media">
                          <ul
                            class="
                              date
                              text-center text-primary
                              mr-3
                              mb-0
                              list-unstyled
                            "
                          >
                            <li class="day font-weight-bold mb-2">11</li>
                            <li class="month font-weight-bold">OCT</li>
                          </ul>
                          <div class="media-body content">
                            <h4>
                              <a
                                href="javascript:void(0)"
                                class="text-dark title"
                                >Business World Event Intro</a
                              >
                            </h4>
                            <p class="text-muted location-time">
                              <span class="text-dark h6">Address:</span> Hall 3,
                              Sinchang-dong, Kwangju,
                              <span class="text-danger">South Korea</span>
                              <br />
                              <span class="text-dark h6">Time:</span> 12:30PM to
                              01:00PM
                            </p>
                            <a
                              href="#tickets"
                              class="btn btn-sm btn-outline-primary mouse-down"
                              >Buy Ticket</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-6 mt-4 pt-2">
                    <div class="card event-schedule rounded border">
                      <div class="card-body">
                        <div class="media">
                          <ul
                            class="
                              date
                              text-center text-primary
                              mr-3
                              mb-0
                              list-unstyled
                            "
                          >
                            <li class="day font-weight-bold mb-2">11</li>
                            <li class="month font-weight-bold">OCT</li>
                          </ul>
                          <div class="media-body content">
                            <h4>
                              <a
                                href="javascript:void(0)"
                                class="text-dark title"
                                >Business Conference for professional</a
                              >
                            </h4>
                            <p class="text-muted location-time">
                              <span class="text-dark h6">Address:</span> Hall 3,
                              Sinchang-dong, Kwangju,
                              <span class="text-danger">South Korea</span>
                              <br />
                              <span class="text-dark h6">Time:</span> 01:00PM to
                              02:15PM
                            </p>
                            <a
                              href="#tickets"
                              class="btn btn-sm btn-outline-primary mouse-down"
                              >Buy Ticket</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <div class="text-center pt-1 pb-1">
                    <h4 class="title font-weight-normal mb-0">Second Day</h4>
                  </div>
                </template>
                <div class="row">
                  <div class="col-lg-6 mt-4 pt-2">
                    <div class="card event-schedule rounded border">
                      <div class="card-body">
                        <div class="media">
                          <ul
                            class="
                              date
                              text-center text-primary
                              mr-3
                              mb-0
                              list-unstyled
                            "
                          >
                            <li class="day font-weight-bold mb-2">12</li>
                            <li class="month font-weight-bold">OCT</li>
                          </ul>
                          <div class="media-body content">
                            <h4>
                              <a
                                href="javascript:void(0)"
                                class="text-dark title"
                                >Digital Conference Event Intro</a
                              >
                            </h4>
                            <p class="text-muted location-time">
                              <span class="text-dark h6">Address:</span> Hall 1,
                              Seo-gu, Kwangju,
                              <span class="text-danger">South Korea</span>
                              <br />
                              <span class="text-dark h6">Time:</span> 10:30AM to
                              11:15AM
                            </p>
                            <a
                              href="#tickets"
                              class="btn btn-sm btn-outline-primary mouse-down"
                              >Buy Ticket</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-6 mt-4 pt-2">
                    <div class="card event-schedule rounded border">
                      <div class="card-body">
                        <div class="media">
                          <ul
                            class="
                              date
                              text-center text-primary
                              mr-3
                              mb-0
                              list-unstyled
                            "
                          >
                            <li class="day font-weight-bold mb-2">12</li>
                            <li class="month font-weight-bold">OCT</li>
                          </ul>
                          <div class="media-body content">
                            <h4>
                              <a
                                href="javascript:void(0)"
                                class="text-dark title"
                                >Conference On User Interface</a
                              >
                            </h4>
                            <p class="text-muted location-time">
                              <span class="text-dark h6">Address:</span> Hall 1,
                              Seo-gu, Kwangju,
                              <span class="text-danger">South Korea</span>
                              <br />
                              <span class="text-dark h6">Time:</span> 11:15AM to
                              12:30PM
                            </p>
                            <a
                              href="#tickets"
                              class="btn btn-sm btn-outline-primary mouse-down"
                              >Buy Ticket</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-6 mt-4 pt-2">
                    <div class="card event-schedule rounded border">
                      <div class="card-body">
                        <div class="media">
                          <ul
                            class="
                              date
                              text-center text-primary
                              mr-3
                              mb-0
                              list-unstyled
                            "
                          >
                            <li class="day font-weight-bold mb-2">12</li>
                            <li class="month font-weight-bold">OCT</li>
                          </ul>
                          <div class="media-body content">
                            <h4>
                              <a
                                href="javascript:void(0)"
                                class="text-dark title"
                                >Business World Event Intro</a
                              >
                            </h4>
                            <p class="text-muted location-time">
                              <span class="text-dark h6">Address:</span> Hall 1,
                              Seo-gu, Kwangju,
                              <span class="text-danger">South Korea</span>
                              <br />
                              <span class="text-dark h6">Time:</span> 12:30PM to
                              01:00PM
                            </p>
                            <a
                              href="#tickets"
                              class="btn btn-sm btn-outline-primary mouse-down"
                              >Buy Ticket</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-6 mt-4 pt-2">
                    <div class="card event-schedule rounded border">
                      <div class="card-body">
                        <div class="media">
                          <ul
                            class="
                              date
                              text-center text-primary
                              mr-3
                              mb-0
                              list-unstyled
                            "
                          >
                            <li class="day font-weight-bold mb-2">12</li>
                            <li class="month font-weight-bold">OCT</li>
                          </ul>
                          <div class="media-body content">
                            <h4>
                              <a
                                href="javascript:void(0)"
                                class="text-dark title"
                                >Business Conference for professional</a
                              >
                            </h4>
                            <p class="text-muted location-time">
                              <span class="text-dark h6">Address:</span> Hall 1,
                              Seo-gu, Kwangju,
                              <span class="text-danger">South Korea</span>
                              <br />
                              <span class="text-dark h6">Time:</span> 01:00PM to
                              02:15PM
                            </p>
                            <a
                              href="#tickets"
                              class="btn btn-sm btn-outline-primary mouse-down"
                              >Buy Ticket</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <div class="text-center pt-1 pb-1">
                    <h4 class="title font-weight-normal mb-0">Third Day</h4>
                  </div>
                </template>
                <div class="row">
                  <div class="col-lg-6 mt-4 pt-2">
                    <div class="card event-schedule rounded border">
                      <div class="card-body">
                        <div class="media">
                          <ul
                            class="
                              date
                              text-center text-primary
                              mr-3
                              mb-0
                              list-unstyled
                            "
                          >
                            <li class="day font-weight-bold mb-2">13</li>
                            <li class="month font-weight-bold">OCT</li>
                          </ul>
                          <div class="media-body content">
                            <h4>
                              <a
                                href="javascript:void(0)"
                                class="text-dark title"
                                >Digital Conference Event Intro</a
                              >
                            </h4>
                            <p class="text-muted location-time">
                              <span class="text-dark h6">Address:</span> Hall 4,
                              Gwangsan-gu, Kwangju,
                              <span class="text-danger">South Korea</span>
                              <br />
                              <span class="text-dark h6">Time:</span> 10:30AM to
                              11:15AM
                            </p>
                            <a
                              href="#tickets"
                              class="btn btn-sm btn-outline-primary mouse-down"
                              >Buy Ticket</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-6 mt-4 pt-2">
                    <div class="card event-schedule rounded border">
                      <div class="card-body">
                        <div class="media">
                          <ul
                            class="
                              date
                              text-center text-primary
                              mr-3
                              mb-0
                              list-unstyled
                            "
                          >
                            <li class="day font-weight-bold mb-2">13</li>
                            <li class="month font-weight-bold">OCT</li>
                          </ul>
                          <div class="media-body content">
                            <h4>
                              <a
                                href="javascript:void(0)"
                                class="text-dark title"
                                >Conference On User Interface</a
                              >
                            </h4>
                            <p class="text-muted location-time">
                              <span class="text-dark h6">Address:</span> Hall 4,
                              Gwangsan-gu, Kwangju,
                              <span class="text-danger">South Korea</span>
                              <br />
                              <span class="text-dark h6">Time:</span> 11:15AM to
                              12:30PM
                            </p>
                            <a
                              href="#tickets"
                              class="btn btn-sm btn-outline-primary mouse-down"
                              >Buy Ticket</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-6 mt-4 pt-2">
                    <div class="card event-schedule rounded border">
                      <div class="card-body">
                        <div class="media">
                          <ul
                            class="
                              date
                              text-center text-primary
                              mr-3
                              mb-0
                              list-unstyled
                            "
                          >
                            <li class="day font-weight-bold mb-2">13</li>
                            <li class="month font-weight-bold">OCT</li>
                          </ul>
                          <div class="media-body content">
                            <h4>
                              <a
                                href="javascript:void(0)"
                                class="text-dark title"
                                >Business World Event Intro</a
                              >
                            </h4>
                            <p class="text-muted location-time">
                              <span class="text-dark h6">Address:</span> Hall 4,
                              Gwangsan-gu, Kwangju,
                              <span class="text-danger">South Korea</span>
                              <br />
                              <span class="text-dark h6">Time:</span> 12:30PM to
                              01:00PM
                            </p>
                            <a
                              href="#tickets"
                              class="btn btn-sm btn-outline-primary mouse-down"
                              >Buy Ticket</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-6 mt-4 pt-2">
                    <div class="card event-schedule rounded border">
                      <div class="card-body">
                        <div class="media">
                          <ul
                            class="
                              date
                              text-center text-primary
                              mr-3
                              mb-0
                              list-unstyled
                            "
                          >
                            <li class="day font-weight-bold mb-2">13</li>
                            <li class="month font-weight-bold">OCT</li>
                          </ul>
                          <div class="media-body content">
                            <h4>
                              <a
                                href="javascript:void(0)"
                                class="text-dark title"
                                >Business Conference for professional</a
                              >
                            </h4>
                            <p class="text-muted location-time">
                              <span class="text-dark h6">Address:</span> Hall 4,
                              Gwangsan-gu, Kwangju,
                              <span class="text-danger">South Korea</span>
                              <br />
                              <span class="text-dark h6">Time:</span> 01:00PM to
                              02:15PM
                            </p>
                            <a
                              href="#tickets"
                              class="btn btn-sm btn-outline-primary mouse-down"
                              >Buy Ticket</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Schedule End -->

    <!-- CTA Start -->
    <section
      class="section bg-cta"
      data-jarallax='{"speed": 0.5}'
      style="background: url('images/event/cta.jpg') center center"
      id="cta"
    >
      <div class="bg-overlay bg-overlay-gradient"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title">
              <h4 class="title title-dark text-white mb-4">
                Digital International Conference 2019
              </h4>
              <p class="text-light para-desc para-dark mx-auto">
                Start working with Landrick that can provide everything you need
                to generate awareness, drive traffic, connect.
              </p>
              <a
                href="javascript: void(0);"
                v-b-modal.modal-1
                class="play-btn border border-light mt-4 video-play-icon"
              >
                <play-icon
                  class="fea icon-ex-md text-white title-dark"
                ></play-icon>
              </a>
              <b-modal
                id="modal-1"
                hide-footer
                size="lg"
                header-close-variant="white"
                header-class="border-0"
                content-class="border-0"
                centered
              >
                <vimeo-player
                  ref="player"
                  :player-width="750"
                  :player-height="450"
                  :video-id="12022651"
                />
              </b-modal>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    s
    <!--end section-->
    <!-- CTA End -->

    <!-- Price Start -->
    <section class="section" id="tickets">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Our Tickets Rates</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row align-items-center">
          <div class="col-md-4 col-12 mt-4 pt-2">
            <div
              class="
                card
                pricing-rates
                best-plan
                bg-light
                text-center
                border-0
                rounded
              "
            >
              <div class="card-body py-5">
                <img
                  src="/images///icon/ticket1.svg"
                  class="mb-4"
                  height="50"
                  alt=""
                />
                <h2 class="title text-uppercase mb-4">1 Day Tickets</h2>
                <div class="d-flex justify-content-center mb-4">
                  <span class="h4 mb-0 mt-2">$</span>
                  <span class="price h1 mb-0">09</span>
                  <span class="h4 align-self-end mb-1">/Day</span>
                </div>

                <p class="text-muted">
                  Start working with Landrick that can provide everything you
                  need to generate awareness, drive traffic, connect.
                </p>
                <a
                  href="javascript:void(0)"
                  class="btn btn-outline-primary btn-sm mt-4"
                  >Buy Tickets</a
                >
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 col-12 mt-4 pt-2">
            <div
              class="
                card
                pricing-rates
                best-plan
                overflow-hidden
                text-center
                border-0
                shadow
                rounded
              "
            >
              <div class="card-body py-5">
                <div class="lable text-center bg-primary pt-2 pb-2">
                  <h6 class="best text-white title-dark mb-0 text-uppercase">
                    Full
                  </h6>
                </div>
                <img
                  src="/images///icon/ticket2.svg"
                  class="mb-4"
                  height="50"
                  alt=""
                />
                <h2 class="title text-uppercase mb-4">Full Tickets</h2>
                <div class="d-flex justify-content-center mb-4">
                  <span class="h4 mb-0 mt-2">$</span>
                  <span class="price h1 mb-0">24</span>
                  <span class="h4 align-self-end mb-1">/Full</span>
                </div>

                <p class="text-muted">
                  Start working with Landrick that can provide everything you
                  need to generate awareness, drive traffic, connect.
                </p>
                <a
                  href="javascript:void(0)"
                  class="btn btn-outline-primary btn-sm mt-4"
                  >Buy Tickets</a
                >
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 col-12 mt-4 pt-2">
            <div
              class="
                card
                pricing-rates
                best-plan
                bg-light
                text-center
                border-0
                rounded
              "
            >
              <div class="card-body py-5">
                <img
                  src="/images///icon/ticket3.svg"
                  class="mb-4"
                  height="50"
                  alt=""
                />
                <h2 class="title text-uppercase mb-4">Couple Tickets</h2>
                <div class="d-flex justify-content-center mb-4">
                  <span class="h4 mb-0 mt-2">$</span>
                  <span class="price h1 mb-0">16</span>
                  <span class="h4 align-self-end mb-1">/Day</span>
                </div>

                <p class="text-muted">
                  Start working with Landrick that can provide everything you
                  need to generate awareness, drive traffic, connect.
                </p>
                <a
                  href="javascript:void(0)"
                  class="btn btn-outline-primary btn-sm mt-4"
                  >Buy Tickets</a
                >
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Events Review</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
          <div class="col-lg-9 mt-4 pt-2 text-center">
            <carousel
              id="owl-fade"
              class="owl-carousel owl-theme"
              dir="ltr"
              :per-page="1"
            >
              <Slide>
                <div class="customer-testi text-center">
                  <p class="text-muted h6 font-italic">
                    " It seems that only fragments of the original text remain
                    in the Lorem Ipsum texts used today. The most well-known
                    dummy text is the 'Lorem Ipsum', which is said to have
                    originated in the 16th century. "
                  </p>
                  <img
                    src="/images///client/01.jpg"
                    class="
                      img-fluid
                      avatar avatar-small
                      mt-3
                      rounded-circle
                      mx-auto
                      shadow
                    "
                    alt=""
                  />
                  <ul class="list-unstyled mb-0 mt-3">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6 class="text-primary">
                    - Thomas Israel <small class="text-muted">C.E.O</small>
                  </h6>
                </div>
                <!--end customer testi-->
              </Slide>
              <Slide>
                <div class="customer-testi text-center">
                  <p class="text-muted h6 font-italic">
                    " The advantage of its Latin origin and the relative
                    meaninglessness of Lorum Ipsum is that the text does not
                    attract attention to itself or distract the viewer's
                    attention from the layout. "
                  </p>
                  <img
                    src="/images///client/02.jpg"
                    class="
                      img-fluid
                      avatar avatar-small
                      mt-3
                      rounded-circle
                      mx-auto
                      shadow
                    "
                    alt=""
                  />
                  <ul class="list-unstyled mb-0 mt-3">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6 class="text-primary">
                    - Carl Oliver <small class="text-muted">P.A</small>
                  </h6>
                </div>
                <!--end customer testi-->
              </Slide>
              <Slide>
                <div class="customer-testi text-center">
                  <p class="text-muted h6 font-italic">
                    " There is now an abundance of readable dummy texts. These
                    are usually used when a text is required purely to fill a
                    space. These alternatives to the classic Lorem Ipsum texts
                    are often amusing and tell short, funny or nonsensical
                    stories. "
                  </p>
                  <img
                    src="/images///client/03.jpg"
                    class="
                      img-fluid
                      avatar avatar-small
                      mt-3
                      rounded-circle
                      mx-auto
                      shadow
                    "
                    alt=""
                  />
                  <ul class="list-unstyled mb-0 mt-3">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6 class="text-primary">
                    - Barbara McIntosh <small class="text-muted">M.D</small>
                  </h6>
                </div>
                <!--end customer testi-->
              </Slide>
              <Slide>
                <div class="customer-testi text-center">
                  <p class="text-muted h6 font-italic">
                    " According to most sources, Lorum Ipsum can be traced back
                    to a text composed by Cicero in 45 BC. Allegedly, a Latin
                    scholar established the origin of the text by compiling all
                    the instances of the unusual word 'consectetur' he could
                    find "
                  </p>
                  <img
                    src="/images///client/04.jpg"
                    class="
                      img-fluid
                      avatar avatar-small
                      mt-3
                      rounded-circle
                      mx-auto
                      shadow
                    "
                    alt=""
                  />
                  <ul class="list-unstyled mb-0 mt-3">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6 class="text-primary">
                    - Christa Smith <small class="text-muted">Manager</small>
                  </h6>
                </div>
                <!--end customer testi-->
              </Slide>
              <Slide>
                <div class="customer-testi text-center">
                  <p class="text-muted h6 font-italic">
                    " It seems that only fragments of the original text remain
                    in the Lorem Ipsum texts used today. The most well-known
                    dummy text is the 'Lorem Ipsum', which is said to have
                    originated in the 16th century. "
                  </p>
                  <img
                    src="/images///client/05.jpg"
                    class="
                      img-fluid
                      avatar avatar-small
                      mt-3
                      rounded-circle
                      mx-auto
                      shadow
                    "
                    alt=""
                  />
                  <ul class="list-unstyled mb-0 mt-3">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6 class="text-primary">
                    - Dean Tolle <small class="text-muted">Developer</small>
                  </h6>
                </div>
                <!--end customer testi-->
              </Slide>
              <Slide>
                <div class="customer-testi text-center">
                  <p class="text-muted h6 font-italic">
                    " It seems that only fragments of the original text remain
                    in the Lorem Ipsum texts used today. One may speculate that
                    over the course of time certain letters were added or
                    deleted at various positions within the text. "
                  </p>
                  <img
                    src="/images///client/06.jpg"
                    class="
                      img-fluid
                      avatar avatar-small
                      mt-3
                      rounded-circle
                      mx-auto
                      shadow
                    "
                    alt=""
                  />
                  <ul class="list-unstyled mb-0 mt-3">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6 class="text-primary">
                    - Jill Webb <small class="text-muted">Designer</small>
                  </h6>
                </div>
                <!--end customer testi-->
              </Slide>
            </carousel>
            <!--end owl carousel-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Price End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-light">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- News Start -->
    <section class="section bg-light">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Event Blog & Latest News</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="/images///event/b01.jpg"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript:void(0)"
                    class="card-title title text-dark"
                    >Design your apps in your own way</a
                  >
                </h5>
                <div class="post-meta d-flex justify-content-between mt-3">
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item mr-2 mb-0">
                      <a href="javascript:void(0)" class="text-muted like"
                        ><i class="mdi mdi-heart-outline mr-1"></i>33</a
                      >
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript:void(0)" class="text-muted comments"
                        ><i class="mdi mdi-comment-outline mr-1"></i>08</a
                      >
                    </li>
                  </ul>
                  <router-link
                    to="/page-blog-detail"
                    class="text-muted readmore"
                    >Read More <i class="mdi mdi-chevron-right"></i
                  ></router-link>
                </div>
              </div>
              <div class="author">
                <small class="text-light user d-block"
                  ><i class="mdi mdi-account"></i> Calvin Carlo</small
                >
                <small class="text-light date"
                  ><i class="mdi mdi-calendar-check"></i> 13th August,
                  2019</small
                >
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="/images///event/b02.jpg"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript:void(0)"
                    class="card-title title text-dark"
                    >How apps is changing the IT world</a
                  >
                </h5>
                <div class="post-meta d-flex justify-content-between mt-3">
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item mr-2 mb-0">
                      <a href="javascript:void(0)" class="text-muted like"
                        ><i class="mdi mdi-heart-outline mr-1"></i>33</a
                      >
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript:void(0)" class="text-muted comments"
                        ><i class="mdi mdi-comment-outline mr-1"></i>08</a
                      >
                    </li>
                  </ul>
                  <router-link
                    to="/page-blog-detail"
                    class="text-muted readmore"
                    >Read More <i class="mdi mdi-chevron-right"></i
                  ></router-link>
                </div>
              </div>
              <div class="author">
                <small class="text-light user d-block"
                  ><i class="mdi mdi-account"></i> Calvin Carlo</small
                >
                <small class="text-light date"
                  ><i class="mdi mdi-calendar-check"></i> 13th August,
                  2019</small
                >
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="/images///event/b03.jpg"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript:void(0)"
                    class="card-title title text-dark"
                    >Smartest Applications for Business</a
                  >
                </h5>
                <div class="post-meta d-flex justify-content-between mt-3">
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item mr-2 mb-0">
                      <a href="javascript:void(0)" class="text-muted like"
                        ><i class="mdi mdi-heart-outline mr-1"></i>33</a
                      >
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript:void(0)" class="text-muted comments"
                        ><i class="mdi mdi-comment-outline mr-1"></i>08</a
                      >
                    </li>
                  </ul>
                  <router-link
                    to="/page-blog-detail"
                    class="text-muted readmore"
                    >Read More <i class="mdi mdi-chevron-right"></i
                  ></router-link>
                </div>
              </div>
              <div class="author">
                <small class="text-light user d-block"
                  ><i class="mdi mdi-account"></i> Calvin Carlo</small
                >
                <small class="text-light date"
                  ><i class="mdi mdi-calendar-check"></i> 13th August,
                  2019</small
                >
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Sign up for our Newsletter</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center mt-4 pt-2">
          <div class="col-lg-7 col-md-10">
            <form>
              <div class="form-group">
                <div class="input-group mb-3">
                  <input
                    name="email"
                    id="email"
                    type="email"
                    class="form-control"
                    placeholder="Your email :"
                    required
                    aria-describedby="newssubscribebtn"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-primary submitBnt"
                      type="submit"
                      id="newssubscribebtn"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!--end form-->
          </div>
        </div>
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- End News -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-footer">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style>
.modal-content {
  background-color: transparent;
}
</style>
